<template>
  <div class="courseDetail">
    <!--1v1详情-->
    <div v-if="pageType === 'private'" class="private">
      <div
        class="course_item"
        v-for="(item, index) in privateData"
        :key="item.id"
      >
        <div class="title">{{ index + 1 }}</div>
        <div class="time">{{ classTime(item.started_at, item.ended_at) }}</div>

        <!-- 课程状态       -->
        <div
          class="course-status course-status--cancel"
          v-if="item.status === 3"
        >
          {{ generateColumns("course_canceled") }}
        </div>
        <div
          class="course-status course-status--finish"
          v-if="item.status === 2 && item.is_recorded"
        >
          {{ generateColumns("live_ended") }}
        </div>
        <template v-if="item.status === 2 && !item.is_recorded">
          <div
            class="course-status course-status--finish"
            v-if="item.is_online_course"
          >
            {{ generateColumns("replay_generating") }}
          </div>
          <div class="course-status course-status--finish" v-else>
            {{ generateColumns("completed") }}
          </div>
        </template>
        <div class="course-status course-status--wait" v-if="item.status === 1">
          {{ generateColumns("to_be_class") }}
        </div>

        <!-- 按钮  -->
        <div
          class="button"
          v-if="item.status === 1"
          @click="openWindow(`/private/live/${item.id}`, 1, item.id)"
        >
          {{ generateColumns("intoClassroom") }}
        </div>
        <div
          class="button"
          v-if="item.status === 2 && item.is_recorded"
          @click="Util.openPage(`/course/private/replay/${item.id}`)"
        >
          {{ generateColumns("watch_replay") }}
        </div>
        <div
          class="button button_disable"
          v-if="item.status === 2 && !item.is_recorded"
        >
          {{ generateColumns("watch_replay") }}
        </div>
        <div
          class="button button_disable"
          v-if="item.status === 3 && item.is_recorded === false"
        >
          {{ generateColumns("intoClassroom") }}
        </div>
        <div
          class="button"
          v-if="item.status === 3 && item.is_recorded"
          @click="Util.openPage(`/course/private/replay/${item.id}`)"
        >
          {{ generateColumns("watch_replay") }}
        </div>
      </div>
    </div>
    <!--班课详情-->
    <papa-collapse
      v-else
      :collapse-data="collapseData"
      @collapseChange="collapseChange"
    >
      <div slot="collapse" slot-scope="props" class="classCourse">
        <div
          class="course_item"
          v-for="item in props.collapseItemData.schedules"
          :key="item.id"
        >
          <div class="title">{{ item.name }}</div>
          <div class="time">
            {{ classTime(item.started_at, item.ended_at) }}
          </div>

          <!--          <div class="course-status course-status&#45;&#45;cancel" v-if="item.status ===3">{{}}</div>-->
          <!--          <div class="course-status course-status&#45;&#45;finish" v-else-if="item.status === 2&&item.is_recorded">直播结束</div>-->
          <!--          <template v-if="item.status ===2&&!item.is_recorded">-->
          <!--            <div class="course-status course-status&#45;&#45;finish" v-if="item.is_online_course">回放生成中</div>-->
          <!--            <div class="course-status course-status&#45;&#45;finish" v-else>已完成</div>-->
          <!--          </template>-->
          <!--          <div class="course-status course-status&#45;&#45;wait" v-else-if="item.status ===1">待上课</div>-->

          <div
            class="button"
            v-if="item.started_at > todayZero"
            @click="getClassDetail(item.id)"
          >
            {{ generateColumns("intoClassroom") }}
          </div>
          <div class="button button_disable" v-else>
            {{ generateColumns("intoClassroom") }}
          </div>
          <!--          <div class="button" v-else-if="item.status === 2&&item.is_recorded"-->
          <!--               @click="Util.openPage(`/course/replay/${item.id}`)">观看回放-->
          <!--          </div>-->
          <!--          <div class="button button_disable" v-else-if="item.status === 2&&!item.is_recorded">观看回放-->
          <!--          </div>-->
          <!--          <div class="button" v-if="item.status === 3&&item.is_recorded"-->
          <!--               @click="Util.openPage(`/course/replay/${item.id}`)">观看回放-->
          <!--          </div>-->
          <!--          <div class="button button_disable" v-if="item.status === 3&&!item.is_recorded">{{generateColumns('intoClassroom')}}-->
          <!--          </div>-->
        </div>
      </div>
    </papa-collapse>
  </div>
</template>

<script>
import CourseDetailJs from "./courseDetail.js";

export default CourseDetailJs;
// import Util from '@/utils/utils'
//
// import { getScheduleCourse, getPrivateCourseDetail } from '@/api/course/course'
//
// export default {
//   name: 'courseDetail',
//   data () {
//     return {
//       collapseData: [],
//       privateData: [],
//       courseId: '',
//       dialogVisible: false,
//       pageType: '',
//     }
//   },
//   mounted () {
//     this.courseId = this.$route.params.id || ''
//     this.handlePageType()
//   },
//   computed: {
//     classTime () {
//       return (begin, end) => {
//         let date = Util.formatMoment(begin, 'YYYY/MM/DD')
//         let beginTime = Util.formatMoment(begin, 'HH:mm')
//         let endTime = Util.formatMoment(end, 'HH:mm')
//         return `${date}  ${beginTime} - ${endTime}`
//       }
//     }
//   },
//   methods: {
//     collapseChange () {
//       console.log(1)
//     },
//     getData () {
//       if (this.pageType === 'private') {
//         let params = {
//           course_plan_detail_id: this.courseId
//         }
//         getPrivateCourseDetail(params, (res) => {
//           this.privateData = res
//         })
//       } else {
//         let params = {
//           course_id: this.courseId
//         }
//         getScheduleCourse(params, (res) => {
//           console.log(res)
//           this.collapseData = res
//         })
//       }
//
//     },
//
//     handlePageType () {
//       let routePath = this.$route.path
//       if (routePath.includes('private')) {
//         this.pageType = 'private'
//         this.getData()
//
//       } else if (routePath.includes('public')) {
//         this.pageType = 'public'
//         console.log('public')
//         this.getData()
//
//       } else {
//         this.pageType = 'class'
//         console.log('班课')
//         this.getData()
//       }
//       console.log(this.pageType)
//     },
//     openWindow (url, liveMode, id) {
//       console.log(url)
//       const {href} = this.$router.resolve({
//         name: 'privateLive',
//         params: {
//           id: id
//         }
//       })
//
//       console.log(url, href)
//       Util.getLiveRoomStatus(id, liveMode).then((res) => {
//         Util.openPage(href)
//       }).catch(err => {
//         console.log(err)
//       })
//     }
//   }
// }
</script>

<style lang="less" scoped>
.courseDetail {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: #fff;

  /deep/ .el-collapse {
    border-top: none;
  }

  .course_item {
    display: flex;
    justify-content: space-between;
    height: 58px;
    align-items: center;
    margin-left: 30px;
    .border--bottom;

    .title {
      padding-left: 10px;
      flex: 1;
    }

    .time {
      flex: 1;
    }

    & > .course-status {
      font-size: 14px;
      font-weight: 500;
      color: #29d087;
      flex: 1;
    }

    .course-status--cancel {
      color: #e6e6e6;
    }

    .course-status--finish {
      color: #e6e6e6;
    }
  }

  .replay {
    width: 100%;

    div {
      width: 100%;
    }

    .player-size {
      width: 100%;
      height: auto;
    }
  }

  .classCourse {
    .course_item:last-child {
      border: none;
    }
  }

  .button {
    .button(90px, 36px);
    .button--green;
  }

  .button_disable {
    .button--disable;
  }
}
</style>
