import Util from "@/utils/utils";

import {
  getChapters,
  getPrivateCourseDetail,
  scheduleDetail,
} from "@/api/course/course";
import { Message } from "element-ui";
import { generateColumns, generateTips } from "@/utils/i18n";
export default {
  name: "courseDetail",
  data() {
    return {
      collapseData: [],
      privateData: [],
      courseId: "",
      dialogVisible: false,
      pageType: "",
      todayZero: 0,
      Util,
    };
  },
  mounted() {
    this.todayZero = new Date(new Date().toLocaleDateString()).getTime() / 1000;

    this.courseId = this.$route.params.id || "";
    this.handlePageType();
  },
  computed: {
    classTime() {
      return (begin, end) => {
        let date = Util.formatMoment(begin, "YYYY/MM/DD");
        let beginTime = Util.formatMoment(begin, "HH:mm");
        let endTime = Util.formatMoment(end, "HH:mm");
        return `${date}  ${beginTime} - ${endTime}`;
      };
    },
  },
  methods: {
    generateColumns,
    generateTips,
    collapseChange(obj) {
      // scheduleDetail(obj.activePanel, res=> console.log(res))
    },
    getClassDetail(id) {
      scheduleDetail(id, (res) => {
        if (res.url) {
          window.open(res.url);
        } else {
          Message.error(this.generateTips("no_zhihu_live"));
        }
      });
    },
    getData() {
      if (this.pageType === "private") {
        let params = {
          course_plan_detail_id: this.courseId,
        };
        getPrivateCourseDetail(this.courseId, (res) => {
          this.privateData = res;
        });
      } else {
        getChapters(this.courseId, (res) => {
          console.log(res);
          this.collapseData = res;
        });
      }
    },

    handlePageType() {
      let routePath = this.$route.path;
      if (routePath.includes("private")) {
        this.pageType = "private";
        this.getData();
      } else if (routePath.includes("public")) {
        this.pageType = "public";
        console.log("public");
        this.getData();
      } else {
        this.pageType = "class";
        console.log("班课");
        this.getData();
      }
      console.log(this.pageType);
    },
    openWindow(url, liveMode, id) {
      // console.log(url)
      // const {href} = this.$router.resolve({
      //   name: 'privateLive',
      //   params: {
      //     id: id
      //   }
      // })
      // console.log(url, href)
      Util.getLiveRoomStatus(id, liveMode)
        .then((res) => {
          Util.openPage(url);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
